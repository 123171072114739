import * as React from 'react'
import { RadioGroupBase, RadioGroupItem } from '../radio-group-base'

type RadioGroupBaseProps = Omit<React.ComponentProps<typeof RadioGroupBase>, 'onValueChange'>

type RadioGroupItemProps = React.ComponentProps<typeof RadioGroupItem>

export type RadioOption<T = string> = RadioGroupItemProps & {
  label?: React.ReactNode
  value: T
  description?: string
  tooltip?: string
  endAdornment?: React.ReactNode
}

export type RadioGroupProps<T = string> = RadioGroupBaseProps & {
  options: readonly RadioOption<T>[]
  onValueChange?(value: T): void
  highlightSelected?: boolean
  error?: string
  name?: string
  type?: RadioGroupType
  optionsContainerClassName?: string
  showBorder?: boolean
  label?: string
  tooltip?: string
}

export enum RadioGroupType {
  Default = 'default',
  Description = 'description',
  Outlined = 'outlined',
  Highlited = 'highlited',
}

import React from 'react'
import { SelectVariant } from '~/scalis-components/core/select/select.types'
import { SelectedContentProps } from './selected-content.types'

export const SelectedContent: React.FC<SelectedContentProps> = ({
  variant,
  placeholder,
  icon,
  selectedValue,
  emptyTerm = 'an option',
  hasSelectedValue = false,
  optionIcon,
}) => {
  const defaultPlaceholder = placeholder ?? `Select ${emptyTerm}`
  const value = hasSelectedValue ? selectedValue : defaultPlaceholder

  switch (variant) {
    case SelectVariant.default: {
      return value
    }

    case SelectVariant.inline: {
      return (
        <>
          {value}

          {icon}
        </>
      )
    }

    case SelectVariant.filter: {
      const nodesQuantity = React.Children.count(selectedValue)
      return nodesQuantity > 0 ? `${nodesQuantity}・${placeholder}` : placeholder
    }

    case SelectVariant.withIcon: {
      return (
        <span className='flex items-center gap-2'>
          {hasSelectedValue ? optionIcon : null}
          {value}
        </span>
      )
    }

    default:
      return null
  }
}
